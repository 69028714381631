import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import AboutFour from "@/components/about-four";
import VideoOne from "@/components/video-one";
import TestimonialsOne from "@/components/testimonials-one";
import TeamOne from "@/components/team-one";
import SponsorOne from "@/components/sponsor-one";
import AboutTwo from "@/components/about-two";
import { allClients } from "@/data";
import { Container } from "react-bootstrap";
import Img from "@/components/img";
import Certificate from '@/images/certificate/certificate.webp';
const About = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Page">
        <HeaderOne />
        <PageHeader title="About Dew Pond" name="About" />
        {/* <AboutFour /> */}
        <AboutTwo />
        {/* <VideoOne /> */}
        {/* <TeamOne extraClassName="section_border" /> */}
        {/* <TestimonialsOne /> */}
        <SponsorOne title="Private Sector" logos={allClients.private} />
        <SponsorOne title="Public Sector" logos={allClients.public} />
        <SponsorOne title="Approved By" logos={allClients.approved} />

        <Container style={{ width: '100%', display:'flex', 
          flexDirection: 'column',
          alignItems:'center', marginBottom: '100px' }}>
          <h2 style={{ marginBottom: '30px' }}>Certifications</h2>
          <Img src={Certificate} className=
        "certificateImg" alt="" />
        </Container>
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default About;
